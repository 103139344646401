/**
 * @author: Mai Anh Vu
 * @date: 2024-08-12
 */

.textEx {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-areas: 
   'bubble subkraki_small' 
    'bubble analogy';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 8vw auto;
  padding-top: 1vw;
}

.textEx>.Subkraki {
  grid-area: analogy;
  margin: auto;
}

.textEx>.Subkraki>img {
  height: 30vw;
}

.inputTextEx {
  position: relative;
  width: 90%;
  grid-area: bubble;
  margin: auto;
  display: grid;
  grid-template-areas: 'inputExercise close';
}

.submitButton {
  background-color: var(--primary-lila);
  color: var(--light-blue);
  border: none;
  text-decoration: none;
  border-radius: 4%/12%;
  font-size: 3vw;
  width: auto;
  grid-area: losButton;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.textExerciseAnalogy .bubble:before,
.textExerciseAnalogy .bubble:after {
  right: 17vw;
  top: -1vw;
  border: solid 2vw transparent;
  border-top: 0;
  border-bottom-color: #fff;
  z-index: 0;
}

.textExerciseAnalogy .bubble::before {
  right: 17vw;
  top: -3.8vw;
  border: solid 2vw transparent;
  border-bottom-color: #eee;
  z-index: -1;
}

.textExerciseAnalogy .panel {
  padding: 0.5vw 0 0 0;
}

.textExerciseAnalogy {
  grid-area: analogy;
  white-space: pre-line;
  padding: 0.5vw 0 0 0;
}

.textExerciseAnalogy .bubble {
  position: relative;
  padding: 2vw 3vw 1vw 5vw;
  width: 32vw;
  border-radius: 12px;
  border: 0.5vw solid #eee;
  background-color: white;
  font-size: 2vw;
  font-family: var(--font-family);
}